<template lang="pug">
	div
		div.text-center(v-if="rendering")
			v-progress-circular( indeterminate color="primary" )
		highcharts(v-else :options="chartOptions")
</template>

<script>
import Highcharts from 'highcharts'
import XRange from 'highcharts/modules/xrange'

import { Chart } from 'highcharts-vue'
XRange(Highcharts)
export default {
	name: 'ChartRoadcond',
	components: {
		highcharts: Chart,
	},
	props: {
		loading: {
			type: Boolean,
			default:true
		},
		reload: {
			type: Number,
			default: null
		},
		roadcast: {
			type: Object,
			default: null
		},
		showMinMax: {
			type: Boolean,
			default: true
		},
		timezone: {
			type: String,
			default: 'America/Montreal'
		},
		width: {
			type: Number,
			default: null
		},
		height: {
			type: Number,
			default: 400
		},
		tickInterval: {
			type: Number,
			default: null
		},
		series: {
			type: Array,
			default: null
		},
		yPlotLines: {
			type: Array,
			default: null
		},
		ySoft: {
			type: Object,
			default: null
		},
		prevHour: {
			type: Number,
			default: null
		},
		lastHour: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			'rendering': true,
			'chartOptions': {},
			'roadcondRisk':{
				'DRY':0,
				'WET':2,
				'SNOW_ICE':3,
				'MELTING_SNOW':2,
				'WATER+SNOW':2,
				'DEW':2,
				'FROST':3,
				'ICING_RAIN':3,
				},
			'colors': [
				'#9CCC65', 
				'#FFEE58', 
				'#FFA726', 
				'#EF5350'
			]
		}
	},
	computed: {
	},
	watch: {
		timezone: {
			immediate:true,
			handler() {
				window.moment = this.$moment
				Highcharts.setOptions({
					time: {
						timezone: this.timezone
					}
				})
			}
		},
		loading() {
			if(this.loading) this.rendering = true
		},
		reload() {
			this.reloadChart()
		},
		cast() {
			this.reloadChart()
		}
	},
	mounted() {
		Highcharts.setOptions({
			lang: {
				months: [
					'Janvier', 'Février', 'Mars', 'Avril',
					'Mai', 'Juin', 'Juillet', 'Août',
					'Septembre', 'Octobre', 'Novembre', 'Décembre'
				],
				shortMonths: [
					'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept',
					'Oct', 'Nov', 'Dec'
				],
				weekdays: [
					'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
					'Jeudi', 'Vendredi', 'Samedi'
				]
			}
		})

		/* force loading chart quand le mount est fait apres la fait du chargement par exemple expansion du panel */
		if(!this.loading) this.reloadChart()
	},
	methods: {
		reloadChart() {
			if(this.roadcast) {
				this.rendering = true

				let plotlines = [
					{
						color: '#1976d2',
						width: 2,
						value: this.$moment().unix() * 1000,
					}]
				let cur = this.prevHour
				do {
					cur = (Math.trunc(cur / 86400) + 1) * 86400
					plotlines.push({
						color: '#B0BEC5',
						width: 1,
						zIndex:2,
						value: (cur + this.$moment().tz(this.timezone).utcOffset() * 60 * -1 ) * 1000,
					})
				} while(cur<this.lastHour)


				let chartOptions = {
					chart: {
						type: 'xrange',
						scrollablePlotArea: {
							minWidth: this.width,
						},
						marginBottom: 50,							
						height: this.height						
					},
					credits: {
						enabled: false
					},
					title: {
						text: null
					},
					tooltip: {
							formatter: function() {
								return `<b>${this.series.chart.yAxis[0].categories[this.point.y]}</b> : ${this.point.label}<br/>
									${Highcharts.dateFormat('%A, %e %b, %H:%M', this.point.x)} -
									${Highcharts.dateFormat('%A, %e %b, %H:%M', this.point.x2)}<br/>`
							}
					},
					xAxis: [
						{
							plotLines:plotlines,
							type: 'datetime',
							tickInterval: this.tickInterval,
							gridLineWidth: 1,
							min: this.prevHour * 1000,
							max: this.lastHour * 1000,
							labels: {
								formatter: (data)=> {
									let h = this.$moment(data.value).tz(this.timezone).format('H')
									if(parseInt(h)===0) h = this.$moment(data.value).tz(this.timezone).format('ddd DD')
									return h
								},
								style: {
									color: 'black',
								},
							},
						}
					],
					yAxis: {
							categories: [this.$t('forecasts.cast.rd_cond_list.axisY'), this.$t('forecasts.cast.br_cond_list.axisY')],
							title: null,
							reversed: true
					},
					series: []
				}

				const dataRanged = []
				for(const [ki, kind] of ['rd_cond_list','br_cond_list'].entries()) {
					let range=null
					for(let d of this.roadcast.data) {
						if(!d[kind]) break
						const val = d[kind]
						if(range) {
							range.x2 = d.time * 1000
							if(range.val != val) {						
								dataRanged.push(range)
								range = null
							}
						}
						if(!range) {
							const conds = val.split(',')
							const risk = conds.reduce( (r,c) => this.roadcondRisk[c] > r ? this.roadcondRisk[c] : r, 0)
							range = {
								x: d.time * 1000,
								x2: d.time * 1000,
								y: ki,
								val,
								risk,
								color: this.colors[risk],
								label: conds.map(c => this.$t(`forecasts.roadConds.${c.toUpperCase()}.text`)).join(' / '),
								short: conds.map(c => this.$t(`forecasts.roadConds.${c.toUpperCase()}.short`)).join(' / ')
							}
						}
					}
					if(range) {
						dataRanged.push(range)
					}
				}

				chartOptions.series.push(
					{
						minPointLength: 5,
						showInLegend: false,
						data: dataRanged,
						pointWidth: 28,
						dataLabels: {
							enabled: true,
							format: '<span style="font-size: smaller;">{point.short}</span>',
						}
					}
				)				

				this.$nextTick(() => {
					this.chartOptions = chartOptions
					this.rendering = false
				})
			}
		}
	}
}
</script>

<style scoped>
</style>
