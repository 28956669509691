<template lang="pug">
  div
    div.text-center(v-if="rendering")
      v-progress-circular( indeterminate color="primary" )
    content-x-scroller(v-else :width="width")
      highcharts(
        v-for="(cast,i) in casts"
        :key="`chart-${i}`"
        ref="charts"
        :options="chartOptions[cast.idModel]")
</template>

<script>
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import ContentXScroller from '@/components/Forecasts/ContentXScroller'

export default {
  name: 'ChartAcc',
  components: {
    highcharts: Chart,
    ContentXScroller
  },
  props: {
    loading: {
      type: Boolean,
      default:true
    },
    reload: {
      type: Number,
      default: null
    },
    casts: {
      type: Array,
      default: null
    },
    showMinMax: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: null
    },
    tickInterval: {
      type: Number,
      default: 36e5
    },
    timezone: {
      type: String,
      default: 'America/Montreal'
    },
    type: {
      type: String,
      default: 'rain'
    },
  },
  data() {
    return {
      'rendering': true,
      'chartOptions': {},
    }
  },

  computed: {
    unit() {
      return this.$t(`forecasts.cast.${this.type}.unit`)
    }
  },
  watch: {
    timezone: {
      immediate:true,
      handler() {
        window.moment = this.$moment
        Highcharts.setOptions({
          time: {
            timezone: this.timezone
          }
        })
      }
    },
    loading() {
      if(this.loading) this.rendering = true
    },
    reload() {
      this.reloadCharts()
    },
  },
  mounted() {
    Highcharts.setOptions({
      lang: {
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        shortMonths: [
          'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept',
          'Oct', 'Nov', 'Dec'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ]
      }
    })

    /* force loading chart quand le mount est fait apres la fait du chargement par exemple expansion du panel */
    if(!this.loading) this.reloadCharts()
  },
  methods: {
    reloadCharts() {
      if(this.casts && this.casts.length>0) {
        this.rendering = true

        let prevHour = Math.trunc(this.$moment().unix() / 3600) * 3600
        let lastHour = prevHour
        this.casts.forEach((cast) => {
          cast.data.map(d => {if(d.time>lastHour) lastHour=d.time})
        })

        let plotlines = [
          {
            color: '#1976d2',
            width: 2,
            value: this.$moment().unix() * 1000,
          }]
        let cur = prevHour
        do {
          cur = (Math.trunc(cur / 86400) + 1) * 86400
          plotlines.push({
            color: '#B0BEC5',
            zIndex:2,
            value: (cur + this.$moment().tz(this.timezone).utcOffset() * 60 * -1 ) * 1000,
          })
        } while(cur<lastHour)

        this.casts.forEach((cast) => {

          let lastTime = cast.data[cast.data.length - 1].time

          let chartOptions = {
            chart: {
              zoomType: 'xy',
              height: 90,
              type: 'column',
              scrollablePlotArea: {
                minWidth: this.width,
              },
              marginLeft: 84
            },
            title: {
              text: null
            },
            legend: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },

            plotOptions: {
              column: {
                pointPlacement: 'on',
                enableMouseTracking: true,
                dataLabels: {
                  enabled: true,
                  allowOverlap: true,
                  y: -11,
                  crop: false,
                  padding: 0,
                  rotation: -90,
                  style: {
                    fontSize: '10px',
                  },
                  formatter: function () {
                    if (this.y == 0) return null
                    let value = Math.round(this.y * 10) / 10
                    return value
                  },
                },
              },
            },

            xAxis: {
              plotLines: plotlines,
              min: prevHour *1000,
              max: lastHour *1000,
              type: 'datetime',
              tickInterval: this.tickInterval,
              gridLineWidth: 0,
              labels: {
                formatter: (data)=> {
                  let h = this.$moment(data.value).tz(this.timezone).format('H')
                  return h
                },
                style: {
                  color: 'black',
                },
              },
              plotBands: [{
                color: '#ECEFF1', // Color value
                from: lastTime * 1000, // Start of the plot band
                to: lastHour * 1000 // End of the plot band
              }],
            },

            yAxis: {
              min: 0,
              max: 3,
              zoomEnabled: false,
              labels: {
                enabled: false,
                useHTML: true,
                format: '{value} ' + this.unit
              },
              gridLineWidth: 0,
              title: {
                text: cast.idModel,
                rotation: -90
              },
            },

            tooltip: {
              shared: true
            },

            series: []
          }


          let castDataAvg = cast.data.map(d => {
              return {
                x: d.time * 1000,
                y: d[this.type + '_avg'],
                min: d[this.type + '_min'],
                max: d[this.type + '_max']
              }
            }
          )
          chartOptions.series.push(
              {
                id: 'val',
                name: this.type + ' moy. ' + cast.idModel,
                stack: 'period',
                data:  castDataAvg,
                color: this.gForecastModelColor(cast.idModel),
                tooltip: {
                  pointFormat: '<br><span style="padding-top:2px; font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}' + this.unit + '</b>' + ((this.showMinMax) ? '<span style="font-size: smaller;"> {point.min:.1f} / {point.max:.1f}</span>' : '')
                },
                marker: {
                  enabled: false
                }
              }
          )

          let maxAcc = 0
          let castDataAvgSum = cast.data.map(d =>{
            return {
              x: d.time * 1000,
              y: d[this.type + '_avg_sum'],
              min: d[this.type + '_min_sum'],
              max: d[this.type + '_max_sum']
            }
          })
          castDataAvgSum.forEach((d) => {
            if( maxAcc < d.y ) maxAcc = d.y
          })
          chartOptions.series.push(
            {
              id:'acc',
              name: this.type + ' acc. moy. ' + cast.idModel,
              stack: 'period',
              data: castDataAvgSum,
              color: this.gForecastModelColor(cast.idModel),
              opacity: 0.7,
              tooltip: {
                pointFormat: '<br><span style="padding-top:2px; font-weight: bold; color: {series.color}; opacity: 0.7">{series.name}</span>: <b>{point.y:.1f}' + this.unit + '</b> ' + ((this.showMinMax) ? '<span style="font-size: smaller;"> {point.min:.1f} / {point.max:.1f}</span>' : '')
              },
            }
          )
          chartOptions.yAxis.max = Math.max(8, maxAcc + 6)

          this.chartOptions[cast.idModel] = chartOptions

        })

        // display day on xAxis only for last grah
        this.chartOptions[this.casts[this.casts.length-1].idModel].xAxis.labels.formatter = (data)=> {
          let h = this.$moment(data.value).tz(this.timezone).format('H')
          if(parseInt(h)===0) h = this.$moment(data.value).tz(this.timezone).format('ddd DD')
          return h
        }

        this.$nextTick(() => {
					this.rendering = false
				})
      }
    }
  }
}
</script>

<style scoped>
</style>
