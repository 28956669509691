<template lang="pug">
v-container(fluid)
	v-row
		v-col(cols=4 md=2)
			v-select(v-model="selected" :items="validCasts" item-text="idModel" item-value="idModel" label="Model")
		v-col(v-if="cast" cols=12 v-for="(ch, chi) in charts" :key="`indices-chart-${chi}`")
			chart-series(
				:reload="reload"	
				:loading="loading"
				:cast="cast"
				:showMinMax="showMinMax"
				:tickInterval="tickInterval"
				:width="width"
				:timezone="timezone"
				:series="ch"
				:height=250
				:prevHour="prevHour"
				:lastHour="lastHour"
			)
		v-col(v-if="hasTstm" cols=12)
			div
				span
					| {{ $t('forecasts.label.tstmRiskTitle') }}
				span(style='font-size:smaller')
					| {{ $t('forecasts.label.tstmRiskTitleInfo') }}
			chart-indices(
				:reload="reload"	
				:loading="loading"
				:cast="publicast"
				:showMinMax="showMinMax"
				:tickInterval="tickInterval"
				:width="width"
				:timezone="timezone"
				:series="['tstm_risk', 'tstm_sev']"
				:height=170
				:prevHour="prevHour"
				:lastHour="lastHour - (2*3600)"
			)
</template>

<script>
import ChartSeries from '@/components/Forecasts/ChartSeries'
import ChartIndices from '@/components/Forecasts/ChartIndices'

export default {
  name: 'Indices',
  components: {
		ChartSeries,
		ChartIndices
  },
	props: {
		loading: {
			type: Boolean,
			default:true
		},
		reload: {
			type: Number,
			default: null
		},
		casts: {
			type: Array,
			default: null
		},
		showMinMax: {
			type: Boolean,
			default: true
		},
		timezone: {
			type: String,
			default: 'America/Montreal'
		},
		width: {
			type: Number,
			default: null
		},
		tickInterval: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			'selected': null,
			'charts': [
				['cape','cin'],
				['lftx','kx','totalx']
			]
		}
	},
  computed: {
		validCasts() {
			return this.casts.filter(c => {
				let valid = true
				this.charts.forEach(ch => ch.forEach(s => c.data[0][s + '_avg' ] == null ? valid=false : null))
				return valid
			})
		},
		publicast() {
			return this.casts.find(c => c.idModel == 'publicast')
		},
		hasTstm() {
			return this.publicast && this.publicast.data[0] 
				&& this.publicast.data[0]['tstm_sev_avg'] != null 
				&& this.publicast.data[0]['tstm_risk_avg'] != null
		},
		cast() {
			return this.validCasts.find(c => c.idModel == this.selected)
		},
		prevHour() {
			return Math.trunc(this.$moment().unix() / 3600) * 3600
		},
		lastHour() {
			let lastHour = this.prevHour
			this.casts.forEach((cast) => {
				cast.data.map(d => {if(d.time>lastHour) lastHour=d.time})
			})
			return lastHour
		}
  },
  watch: {
		validCasts() {
			if(!this.validCasts.find(c=>c.idModel == this.selected)) {
				this.selected = this.validCasts.length > 0 ? this.validCasts[0].idModel : null
			}
		}
  },
	created() {
    this.selected = this.validCasts.length > 0 ? this.validCasts[0].idModel : null
  },
  mounted() {
  },
  methods: {
  }
}
</script>
