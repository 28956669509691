<template lang="pug">
  div.content-x-scroller
    slot
    div.p-2.content-x-scrolling(v-if="width")
      div(:style="`min-width:${width}px;height:1px`")
</template>

<script>
export default {
  name: 'ContentXScroller',
  props: {
    width: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss">
  .content-x-scroller {
    .content-x-scrolling {
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .highcharts-scrolling,
    .hidden-scrollbar {
      /* Firefox */
      scrollbar-width: none;
    }

    .highcharts-scrolling::-webkit-scrollbar,
    .hidden-scrollbar::-webkit-scrollbar {
      /* Safari, Chrome */
      display: none;
    }

    .highcharts-scrolling .highcharts-container {
      position: static !important;
    }
  }
</style>
