<template lang="pug">
	div
		div.text-center(v-if="rendering")
			v-progress-circular( indeterminate color="primary" )
		highcharts(v-else :options="chartOptions")
</template>

<script>
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import { Chart } from 'highcharts-vue'

More(Highcharts)
export default {
	name: 'ChartSeries',
	components: {
		highcharts: Chart,
	},
	props: {
		loading: {
			type: Boolean,
			default:true
		},
		reload: {
			type: Number,
			default: null
		},
		cast: {
			type: Object,
			default: null
		},
		showMinMax: {
			type: Boolean,
			default: true
		},
		timezone: {
			type: String,
			default: 'America/Montreal'
		},
		width: {
			type: Number,
			default: null
		},
		height: {
			type: Number,
			default: 400
		},
		tickAmount: {
			type: Number,
			default: 4
		},
		tickInterval: {
			type: Number,
			default: null
		},
		series: {
			type: Array,
			default: null
		},
		yPlotLines: {
			type: Array,
			default: null
		},
		ySoft: {
			type: Object,
			default: null
		},
		yLimit: {
			type: Object,
			default: null
		},
		prevHour: {
			type: Number,
			default: null
		},
		lastHour: {
			type: Number,
			default: null
		},
		yAxisGrouped: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			'rendering': true,
			'chartOptions': {},
			'seriesColor': {
				'cin':'#2E7D32',
				'cape':'#B71C1C',
				'lftx':'#E64A19',
				'kx':'#D81B60',
				'totalx':'#4527A0',
				'rd_ts': '#EF5350',
				'br_ts': '#7B1FA2',
				'tair': '#0277BD',
				'tdew': '#AFB42B'
			}
		}
	},
	computed: {
		unit() {
			return (serie) => {
				return this.$t(`forecasts.cast.${serie}.unit`)
			}
		}
	},
	watch: {
		timezone: {
			immediate:true,
			handler() {
				window.moment = this.$moment
				Highcharts.setOptions({
					time: {
						timezone: this.timezone
					}
				})
			}
		},
		loading() {
			if(this.loading) this.rendering = true
		},
		reload() {
			this.reloadChart()
		},
		cast() {
			this.reloadChart()
		}
	},
	mounted() {
		Highcharts.setOptions({
			lang: {
				months: [
					'Janvier', 'Février', 'Mars', 'Avril',
					'Mai', 'Juin', 'Juillet', 'Août',
					'Septembre', 'Octobre', 'Novembre', 'Décembre'
				],
				shortMonths: [
					'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept',
					'Oct', 'Nov', 'Dec'
				],
				weekdays: [
					'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
					'Jeudi', 'Vendredi', 'Samedi'
				]
			}
		})

		/* force loading chart quand le mount est fait apres la fait du chargement par exemple expansion du panel */
		if(!this.loading) this.reloadChart()
	},
	methods: {
		reloadChart() {
			if(this.cast) {
				this.rendering = true

				let plotlines = [
					{
						color: '#1976d2',
						width: 2,
						value: this.$moment().unix() * 1000,
					}]
				let cur = this.prevHour
				do {
					cur = (Math.trunc(cur / 86400) + 1) * 86400
					plotlines.push({
						color: '#B0BEC5',
						width: 1,
						zIndex:2,
						value: (cur + this.$moment().tz(this.timezone).utcOffset() * 60 * -1 ) * 1000,
					})
				} while(cur<this.lastHour)


				let chartOptions = {
					chart: {
						zoomType: false,
						scrollablePlotArea: {
							minWidth: this.width,
						},
						height: this.height
					},
					credits: {
						enabled: false
					},
					title: {
						text: null
					},
					xAxis: [
						{
							plotLines:plotlines,
							type: 'datetime',
							tickInterval: this.tickInterval,
							gridLineWidth: 1,
							min: this.prevHour * 1000,
							max: this.lastHour * 1000,
							labels: {
								formatter: (data)=> {
									let h = this.$moment(data.value).tz(this.timezone).format('H')
									if(parseInt(h)===0) h = this.$moment(data.value).tz(this.timezone).format('ddd DD')
									return h
								},
								style: {
									color: 'black',
								},
							},
						}
					],

					tooltip: {
						shared: true
					},

					series: []
				}

				if (this.yAxisGrouped) {
					chartOptions.yAxis = { // Primary yAxis
						plotLines: [
							{
								color: '#EF5350',
								width: 1,
								zIndex:2,
								value: 0,
							},
							...this.yPlotLines ? this.yPlotLines : []
						],
						tickAmount: this.tickAmount,
						softMin: this.ySoft !== null && this.ySoft.min ? this.ySoft.min : null,
						softMax: this.ySoft !== null && this.ySoft.max ? this.ySoft.max : null,
						min: this.yLimit !== null && this.yLimit.min ? this.yLimit.min : null,
						max: this.yLimit !== null && this.yLimit.max ? this.yLimit.max : null,
						labels: {
							format: '{value} ' + this.unit(this.series[0]),
							style: {
								color: '#78909C'
							}
						},
						title: {
							text: this.yAxisGrouped,
							style: {
								color: '#000001',
							}
						},
					}
				}
				else {
					chartOptions.yAxis = this.series.map( (s,si) => (
							{ // Primary yAxis
								plotLines: [
									{
										color: '#78909C',
										width: 1,
										zIndex:2,
										value: 0,
									},
									...this.yPlotLines ? this.yPlotLines : []
								],
								tickAmount: this.tickAmount,
								softMin: this.ySoft !== null && this.ySoft.min ? this.ySoft.min : null,
								softMax: this.ySoft !== null && this.ySoft.max ? this.ySoft.max : null,
								min: this.yLimit !== null && this.yLimit.min ? this.yLimit.min : null,
								max: this.yLimit !== null && this.yLimit.max ? this.yLimit.max : null,
								labels: {
									format: '{value} ' + this.unit(s),
									style: {
										color: this.seriesColor[s]
									}
								},
								title: {
									text: this.$t(`forecasts.cast.${s}.axisY`),
									style: {
										color: this.seriesColor[s]
									}
								},
								opposite: si>0
							}))

				}

				this.series.forEach((serie, si) => {

					let castDataAvg = this.cast.data.map(d => [d.time * 1000, d[serie + '_avg']])
					chartOptions.series.push(
							{
								name: 'moy. ' + this.$t(`forecasts.cast.${serie}.axisY`),
								type: 'spline',
								data: castDataAvg,
								color: this.seriesColor[serie],
								tooltip: {
									pointFormat: '<br><span style="padding-top:2px; font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}' + this.unit(serie) + '</b> '
								},
								marker: {
									enabled: false
								},
								yAxis: this.yAxisGrouped ? 0 : si
							}
					)

					if(this.showMinMax) {
						let castDataMinMax = this.cast.data.map(d => [d.time * 1000, d[serie + '_min'], d[serie + '_max']])
						chartOptions.series.push(
								{
									name: 'min-max ' + serie,
									type: 'arearange',
									lineWidth: 0,
									linkedTo: ':previous',
									color: this.seriesColor[serie],
									fillOpacity: 0.3,
									data: (this.showMinMax) ? castDataMinMax : [],
									tooltip: {
										pointFormat: '<span style="font-size: smaller;"> {point.low:.1f} / {point.high:.1f})</span> '
									},
									marker: {
										enabled: false
									},
									yAxis: this.yAxisGrouped ? 0 : si
								}
						)
					}

				})
				this.$nextTick(() => {
					this.chartOptions = chartOptions
					this.rendering = false
				})
			}
		}
	}
}
</script>

<style scoped>
</style>
