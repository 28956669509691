<template lang="pug">
  div
    div.text-center(v-if="rendering")
      v-progress-circular( indeterminate color="primary" )
    highcharts(v-else :options="chartOptions")
</template>

<script>
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import { Chart } from 'highcharts-vue'

More(Highcharts)
export default {
  name: 'Chart',
  components: {
    highcharts: Chart,
  },
  props: {
    loading: {
      type: Boolean,
      default:true
    },
    reload: {
      type: Number,
      default: null
    },
    casts: {
      type: Array,
      default: null
    },
    showMinMax: {
      type: Boolean,
      default: true
    },
    timezone: {
      type: String,
      default: 'America/Montreal'
    },
    width: {
      type: Number,
      default: null
    },
    tickInterval: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: 'tair'
    },
    yPlotLines: {
      type: Array,
      default: null
    },
    ySoft: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      'rendering': true,
      'chartOptions': {}
    }
  },
  computed: {
    unit() {
      return this.$t(`forecasts.cast.${this.type}.unit`)
    },
  },
  watch: {
    timezone: {
      immediate:true,
      handler() {
        window.moment = this.$moment
        Highcharts.setOptions({
          time: {
            timezone: this.timezone
          }
        })
      }
    },
    loading() {
      if(this.loading) this.rendering = true
    },
    reload() {
      this.reloadChart()
    },
  },
  mounted() {
    Highcharts.setOptions({
      lang: {
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        shortMonths: [
          'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept',
          'Oct', 'Nov', 'Dec'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ]
      }
    })

    /* force loading chart quand le mount est fait apres la fait du chargement par exemple expansion du panel */
    if(!this.loading) this.reloadChart()
  },
  methods: {
    reloadChart() {
      if(this.casts && this.casts.length>0) {
        this.rendering = true


        let prevHour = Math.trunc(this.$moment().unix() / 3600) * 3600
        let lastHour = prevHour
        this.casts.forEach((cast) => {
          cast.data.map(d => {if(d.time>lastHour) lastHour=d.time})
        })

        let plotlines = [
          {
            color: '#1976d2',
            width: 2,
            value: this.$moment().unix() * 1000,
          }]
        let cur = prevHour
        do {
          cur = (Math.trunc(cur / 86400) + 1) * 86400
          plotlines.push({
            color: '#B0BEC5',
            width: 1,
            zIndex:2,
            value: (cur + this.$moment().tz(this.timezone).utcOffset() * 60 * -1 ) * 1000,
          })
        } while(cur<lastHour)


        let chartOptions = {
          chart: {
            zoomType: 'xy',
            scrollablePlotArea: {
              minWidth: this.width,
            },
          },
					credits: {
						enabled: false
					},
          title: {
            text: null
          },
          xAxis: [
            {
              plotLines:plotlines,
              type: 'datetime',
              tickInterval: this.tickInterval,
              gridLineWidth: 1,
              min: prevHour * 1000,
              max: lastHour * 1000,
              labels: {
                formatter: (data)=> {
                  let h = this.$moment(data.value).tz(this.timezone).format('H')
                  if(parseInt(h)===0) h = this.$moment(data.value).tz(this.timezone).format('ddd DD')
                  return h
                },
                style: {
                  color: 'black',
                },
              },
            }
          ],

          yAxis: [{ // Primary yAxis
            plotLines: [
              {
                color: '#78909C',
                width: 1,
                zIndex:2,
                value: 0,
              },
              ...this.yPlotLines ? this.yPlotLines : []
            ],
            softMin: this.ySoft !== null && this.ySoft.min ? this.ySoft.min : null,
            softMax: this.ySoft !== null && this.ySoft.max ? this.ySoft.max : null,
            labels: {
              format: '{value} ' + this.unit,
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: this.$t(`forecasts.cast.${this.type}.axisY`),
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            }
          }],

          tooltip: {
            shared: true
          },

          series: []
        }

        this.casts.forEach((cast) => {

          // cas particulier pour le vent pour ajouter la direction
          if(this.type == 'w_avg') {
            const fleches = ['↑','↗','→','↘','↓','↙','←','↖']

            let castDataAvg = cast.data.map(d => {
              let dirTo = d['w_dir_avg'] + (d['w_dir_avg'] > 180 ?  - 180 : 180)
              return {
                x: d.time * 1000,
                y: d[this.type + '_avg'],
                dir: d['w_dir_avg'],
                dir8: fleches[dirTo == 360 ? 0 : Math.floor(dirTo / 45)]
              }
            })
            chartOptions.series.push(
                {
                  name: 'moy. ' + cast.idModel,
                  type: 'spline',
                  data: castDataAvg,
                  zIndex: (cast.idModel == 'publicast') ? 2 : 1,
                  color: this.gForecastModelColor(cast.idModel),
                  tooltip: {
                    pointFormat: '<br><span style="padding-top:2px; font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.dir8} | {point.y:.1f}' + this.unit + '</b> '
                  },
                  marker: {
                    enabled: false
                  }
                }
            )
          }
          else {
            let castDataAvg = cast.data.map(d => [d.time * 1000, d[this.type + '_avg']])
            chartOptions.series.push(
                {
                  name: 'moy. ' + cast.idModel,
                  type: 'spline',
                  data: castDataAvg,
                  zIndex: (cast.idModel == 'publicast') ? 2 : 1,
                  color: this.gForecastModelColor(cast.idModel),
                  tooltip: {
                    pointFormat: '<br><span style="padding-top:2px; font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}' + this.unit + '</b> '
                  },
                  marker: {
                    enabled: false
                  }
                }
            )
          }

          if(this.showMinMax) {
            let castDataMinMax = cast.data.map(d => [d.time * 1000, d[this.type + '_min'], d[this.type + '_max']])
            chartOptions.series.push(
                {
                  name: 'min-max ' + cast.idModel,
                  type: 'arearange',
                  lineWidth: 0,
                  zIndex: (cast.idModel == 'publicast') ? 2 : 1,
                  linkedTo: ':previous',
                  color: this.gForecastModelColor(cast.idModel),
                  fillOpacity: 0.3,
                  data: (this.showMinMax) ? castDataMinMax : [],
                  tooltip: {
                    pointFormat: '<span style="font-size: smaller;"> {point.low:.1f} / {point.high:.1f})</span> '
                  },
                  marker: {
                    enabled: false
                  }
                }
            )
          }

        })

        this.$nextTick(() => {
					this.chartOptions = chartOptions
					this.rendering = false
				})
      }
    }
  }
}
</script>

<style scoped>
</style>
