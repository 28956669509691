<template lang="pug">
v-container(v-if="publicast && yLimit" fluid)
	v-row
		v-col(cols=12)
			chart-series(
					:reload="reload"
					:loading="loading"
					:cast="cast"
					:showMinMax="showMinMax"
					:tickInterval="tickInterval"
					:width="width"
					:timezone="timezone"
					:series="series"
					:height=250
					:prevHour="prevHour"
					:lastHour="lastHour - ((series.length-1)*3600)"
					:tickAmount=7
					:yLimit="yLimit"
					:yAxisGrouped="$t('forecasts.label.temperature')"
				)
	v-row
		v-col(cols=12)
			chart-roadcond(
					:reload="reload"
					:loading="loading"
					:roadcast="{data:roadcast}"
					:showMinMax="showMinMax"
					:tickInterval="tickInterval"
					:width="width"
					:timezone="timezone"
					:height=120
					:prevHour="prevHour"
					:lastHour="lastHour"
				)
</template>

<script>
import ChartSeries from '@/components/Forecasts/ChartSeries'
import ChartRoadcond from '@/components/Forecasts/ChartRoadcond'

export default {
  name: 'Roadcast',
  components: {
		ChartSeries, ChartRoadcond
  },
	props: {
		loading: {
			type: Boolean,
			default:true
		},
		reload: {
			type: Number,
			default: null
		},
		casts: {
			type: Array,
			default: null
		},
		roadcast: {
			type: Array,
			default: null
		},
		showMinMax: {
			type: Boolean,
			default: true
		},
		timezone: {
			type: String,
			default: 'America/Montreal'
		},
		width: {
			type: Number,
			default: null
		},
		tickInterval: {
			type: Number,
			default: null
		}
	},
  computed: {
		publicast() {
			return this.casts.find(c => c.idModel == 'publicast')
		},
		cast() {
			if(!this.roadcast) return []
			if(!this.publicast) return this.roadcast
			return {
				data: this.roadcast.map(d => {
					var pData = this.publicast.data.find(pd => pd.time==d.time)
					for(let c of ['tair_avg','tair_min','tair_max','tdew_avg','tdew_min','tdew_max']) d[c] = pData[c]
					return d
				})
			}
		},
		yLimit() {
			if(!this.cast.data) return null
			return this.cast.data.reduce( (ys,d) => {
				const max = Math.max(d.tair_max, d.tdew_max, d.rd_ts_max || ys.max, d.br_ts_max || ys.max)
				const min = Math.min(d.tair_min, d.tdew_min, d.rd_ts_min || ys.min, d.br_ts_min || ys.min)
				if(ys.min>min) ys.min = min
				if(ys.max<max) ys.max = max
				return ys
			}, {min:100,max:-100})
		},
		series() {
			return ['rd_ts','br_ts','tair','tdew'].reduce( (res, s) => {
				if(this.cast.data.find(d => d[s + '_avg'])) res.push(s)
				return res
			}, [])
		},
		prevHour() {
			return Math.trunc(this.$moment().unix() / 3600) * 3600
		},
		lastHour() {
			let lastHour = this.prevHour
			this.casts.forEach((cast) => {
				cast.data.map(d => {if(d.time>lastHour) lastHour=d.time})
			})
			return lastHour
		}
  }
}
</script>
