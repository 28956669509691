<template lang="pug">
	div.ma-4
		v-container(v-if="loaded" fluid)
			v-row
				v-col(cols=12 md=6)
					v-select(v-model="modelsSelected" :items="modelsIds" outlined attach chips :label="$t('forecasts.label.models')" multiple)
						template(v-slot:selection="{ attrs, item, parent, selected }")
							v-chip(v-bind="attrs" :input-value="selected")
								span
									| {{ item }}
								v-icon.pl-1(small @click.stop.prevent="parent.selectItem(item)") $delete
				v-col(cols=6 md=3)
					v-checkbox(v-if="contextType == 'castzone'"  v-model="showMinMax" :label="$t('forecasts.label.showMinMax')")
				v-col(cols=6 md=3)
					v-select(:value="getRange()" @input="setRange($event)" :items="rangeList"  :label="$t('forecasts.label.range')")
			v-row.mt-n6
				v-col.text-right(cols=12)
					v-btn(@click="panelsAllOpen" x-small text )
						| {{ $t('forecasts.label.openAll') }}
					span.ml-2.mr-2
						| /
					v-btn(@click="panelsAllClose" x-small text )
						| {{ $t('forecasts.label.closeAll') }}
			v-row.mt-4
				v-col
					v-expansion-panels(v-model="panels" multiple ref="panels")
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.label.info.title') }}
							v-expansion-panel-content
								info.pa-3(:timezone="timezone")
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.tair.title') }}
							v-expansion-panel-content
								chart(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
									type="tair",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.cloud.title') }}
							v-expansion-panel-content
								chart(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
									type="cloud",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.rain.title') }}
							v-expansion-panel-content
								chart-acc(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:width="contentWidth"
									:timezone="timezone"
									:tickInterval="tickInterval"
									type="rain",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.snow.title') }}
							v-expansion-panel-content
								chart-acc(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:width="contentWidth"
									:timezone="timezone"
									:tickInterval="tickInterval"
									type="snow",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.rain_frz.title') }}
							v-expansion-panel-content
								chart-acc(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:width="contentWidth"
									:timezone="timezone"
									:tickInterval="tickInterval"
									type="rain_frz",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.ice.title') }}
							v-expansion-panel-content
								chart-acc(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:width="contentWidth"
									:timezone="timezone"
									:tickInterval="tickInterval"
									type="ice",
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.w_avg.title') }}
							v-expansion-panel-content
								chart(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
									:yPlotLines="wind_threshold"
									:ySoft="{max: 60}"
									type="w_avg"
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.cast.w_max.title') }}
							v-expansion-panel-content
								chart(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
									:yPlotLines="wind_threshold"
									:ySoft="{max: 60}"
									type="w_max"
								)
						v-expansion-panel
							v-expansion-panel-header {{ $t('forecasts.label.indice') }}
							v-expansion-panel-content
								indices(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
								)
						v-expansion-panel(v-show='roadcast && roadcast.length>0')
							v-expansion-panel-header {{ $t('forecasts.label.roadcast') }}
							v-expansion-panel-content(v-if='roadcast && roadcast.length>0')
								div
									span(style='font-size:smaller')
										| {{ $t('forecasts.label.roadcastInfo') }}
								roadcast(
									:reload="reloader"
									:loading="reloading"
									:casts="castsFiltered"
									:roadcast="roadcast"
									:showMinMax="showMinMax"
									:tickInterval="tickInterval"
									:width="contentWidth"
									:timezone="timezone"
								)
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Chart from '@/components/Forecasts/Chart'
import ChartAcc from '@/components/Forecasts/ChartAcc'
import Indices from '@/components/Forecasts/Indices'
import Roadcast from '@/components/Forecasts/Roadcast'
import Info from '@/components/Forecasts/Info'

export default {
	components: {Info, Chart, ChartAcc, Indices, Roadcast},
	data() {
		return {
			'reloading':true,
			'reloader':0,
			'reloaderT':false,
			'modelsSelected':[],
			'loaded': false,
			'showMinMax':false,
			'panelsDefault': [1,2,3,4,5,6,7,8,9,10],
			'rangeList': [
				{value:-1,text:'Tout'},
				{value:24,text:'24h'},
				{value:48,text:'48h'},
				{value:72,text:'72h'},
			],
			'range': 48,
			'panels': [],
		}
	},
	computed: {
		...mapState('forecast', ['castpoints','models','casts','roadcast','contextHash','contextType']),
		castsHeader() {
			return [
				{ text: 'Date', value: 'dt' },
				{ text: 'Tair Avg', value: 'tair_avg' },
				{ text: 'Tair Min', value: 'tair_min' },
				{ text: 'Tair Max', value: 'tair_max' }
			]
		},
		castsFiltered() {
			return this.casts.filter(c => this.modelsSelected.find(m => m == c.idModel) ).sort((a,b)=> (this.models[a.idModel].order > this.models[b.idModel].order) ? 1 : -1)
		},
		timezone() {
			const cpKeys = Object.keys(this.castpoints)
			return (cpKeys.length>0) ? this.castpoints[cpKeys[0]].timezone : 'America/Montreal'
		},
		castsFilteredDuration() {
			let minTime = Number.MAX_VALUE
			let maxtime = Number.MIN_VALUE

			for(let cast of this.castsFiltered) {
				cast.data.map(d => {
					if(d.time>maxtime) maxtime = d.time
					else if(d.time<minTime) minTime = d.time
				})
			}

			return (maxtime - minTime) / 3600
		},
		contentWidth() {
			let containterWidth = window.innerWidth - 119
			return (this.range != -1) ? Math.trunc((containterWidth * this.castsFilteredDuration) / this.range) : undefined
		},
		tickInterval() {
			return ((this.range!=-1) ? Math.trunc( this.range / 24) : 6) * 36e5
		},
		modelsIds() {
			return Object.keys(this.models).sort((a,b)=> (this.models[a].order > this.models[b].order) ? 1 : -1)
		},
		wind_threshold() {
			return [
				{
					color: 'red', // Color value
					dashStyle: 'solid', // Style of the plot line. Default to solid
					value: 50, // Value of where the line will appear
					width: 2, // Width of the line,
					zIndex: 2,
				}
			]
		},
	},
	watch: {
		contextHash() {
			this.fetchData()
		},
		loaded() {
			this.triggerReloader()
		},
		showMinMax() {
			this.initReloader()
		},
		contentWidth() {
			this.initReloader()
		},
		castsFiltered() {
			this.initReloader()
		},
		panels() {
			localStorage.setItem('sentinelle.castboard.panels', JSON.stringify(this.panels))				
			this.initXScroll()			
		}
	},
	created() {
		let ps = localStorage.getItem('sentinelle.castboard.panels')		
		if (!ps) ps = this.panelsDefault
		else ps = JSON.parse(ps)
		for(const keys of ps) {
				if(!this.panels.includes(keys)) this.panels.push(keys)
		}
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		...mapActions('forecast', ['fetchCastzoneContext','fetchCastOfCastpoints','fetchRoadcastOfCastpoints','resetCasts','resetCastpoints']),
		setRange(newValue) {
			localStorage.setItem('sentinelle.castboard.range', newValue)
			this.range = newValue
		},
		getRange() {
			if(localStorage.getItem('sentinelle.castboard.range')) this.range = Number(localStorage.getItem('sentinelle.castboard.range'))
			return this.range
		},		
		triggerReloader() {
			this.reloader++
			this.reloading=false
			this.initXScroll()
		},
		initReloader() {
			this.reloading = true
			if(this.reloaderT) clearTimeout(this.reloaderT)
			this.reloaderT = setTimeout(this.triggerReloader,500)
		},
		initXScroll() {
			let self = this
			setTimeout(function (){
				let scrolls = [...document.getElementsByClassName('highcharts-scrolling'), ...document.getElementsByClassName('content-x-scrolling')]
				let cur = 0
				for (let scroll of scrolls) {
					scroll.removeEventListener('scroll', self.handleXScroll)
					scroll.addEventListener('scroll', self.handleXScroll)
					if(scroll.scrollLeft > cur) cur = scroll.scrollLeft
				}

				if(cur) {
					for (let scroll of scrolls) {
						scroll.scrollLeft = cur
					}
				}

			}, 1500)
		},
		handleXScroll(event) {
			let scrolls = [...document.getElementsByClassName('highcharts-scrolling'), ...document.getElementsByClassName('content-x-scrolling')]
			for (let scroll of scrolls) {
				if (scroll == event.target) continue
				scroll.scrollLeft = event.target.scrollLeft
			}
		},
		panelsAllOpen() {
			for(const keys of Array.from(Array(this.$refs.panels.items.length).keys())) {
				if(!this.panels.includes(keys)) this.panels.push(keys)
			}
		},
		panelsAllClose() {
			this.panels.splice(0, this.panels.length)
		},
		async fetchData() {
			this.loaded = false
			await this.resetCasts()

			const modelsPrevious = this.modelsIds

			if(this.modelsSelected.length == 0) {
				this.modelsSelected = this.modelsIds
			}
			else {
				const modelsNew = this.modelsIds.filter(m=>!modelsPrevious.find(mp=>m==mp))
				this.modelsSelected = this.modelsIds.filter(m=> this.modelsSelected.find(ms=>m==ms) || modelsNew.find(mn => m==mn))
			}

			let fetchers = []
			let completedFetches = 0
			let logResolve = () => {
				completedFetches++
				this.gLoadingMsg(this.$t('forecasts.loading.casts', {cur: completedFetches, nb: fetchers.length}))
			}

			for(let idModel of this.modelsIds) {
				fetchers.push(this.fetchCastOfCastpoints({idModel, idCastpoints: Object.keys(this.castpoints)}).then(logResolve))
			}
			fetchers.push(this.fetchRoadcastOfCastpoints({idCastpoints: Object.keys(this.castpoints)}).then(logResolve))

			this.gLoading(this.$t('forecasts.loading.casts',{cur:0, nb: fetchers.length}))
			
			Promise.all(
					fetchers
			).then(() => {
				this.loaded=true
				this.gLoadingEnd()
			})
		}
	},
}
</script>

<style scoped>
</style>
