<template lang="pug">
  v-row
    v-col(v-if="isContextCastzone" cols=12 md=6)
      h5
        span
          | {{ $tc('forecasts.label.info.castpoints', Object.keys(castpoints).length ) }}
      l-map.mt-1.mapzone(ref="mapzone" :zoom="4" :center="[47, 2]" style="height: 25vh")
        l-tile-layer(url="https://tile.openstreetmap.org/{z}/{x}/{y}.png")
        l-feature-group(ref="featurezone")
          l-marker(
            v-for="(point, idx) in castpoints"
            :key="`marker${idx}`"
            :lat-lng="[point.latitude, point.longitude]"
            @click="gForecastCastpoint(point.id)"
            )
            l-tooltip {{ point.name }}
            l-icon(:icon-anchor="[7,7]" :tooltip-anchor="[0,11]")
              div.marker-castpoint(:style="`background-color:#1976d2`" )
          l-geo-json(:geojson="castzone.geometry")
    v-col(cols=12 md=6)
      h5
        span
          | {{ $tc('forecasts.label.info.models', Object.keys(models).length ) }}
      div
        div(v-for="(m,i) in models" :key="`info-m-${i}`")
          span.text-body-1.pb-2 {{ m.model }} -&nbsp;
            small {{ $t('forecasts.label.info.lastUpdate', {date: $moment(m.time * 1000).tz(timezone).format('ddd DD/MM HH:mm') })  }}
      h5.mt-4
        span
          | {{ $tc('forecasts.label.info.timezone', Object.keys(models).length ) }}
      div
        span.text-body-2
          | {{ timezone }} - {{ $moment().tz(timezone).format('ddd DD/MM HH:mm') }}
</template>

<script>
import {LTooltip, LMap, LTileLayer, LGeoJson, LFeatureGroup, LMarker, LIcon} from 'vue2-leaflet'
import { mapState } from 'vuex'


export default {
  name: 'Info',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LFeatureGroup,
    LIcon,
    LTooltip
  },
  props: {
    timezone: {
      type: String,
      default: 'America/Montreal'
    }
  },
  watch: {
    castpoints: {
      immediate:true,
      handler() {
        if(this.castpoints && Object.keys(this.castpoints).length>0 && this.isContextCastzone ) this.fitCastpointsMap()
      }
    }
  },
  computed: {
    ...mapState('forecast', ['castpoints','models','contextType','castzone']),
    isContextCastzone() {
      return this.contextType == 'castzone'
    }
  },
  mounted() {
  },
  methods: {
    fitCastpointsMap() {
      setTimeout(() => {
        if(this.$refs.mapzone) {
          // invalidate size to force redraw (size could be wrong due to map in v-dialog)
          // see : https://stackoverflow.com/questions/56364129/vue2-leaflet-map-not-showing-properly-in-boostrapvue-modal/56364130#56364130
          // also see : https://stackoverflow.com/questions/36246815/data-toggle-tab-does-not-download-leaflet-map
          this.$refs.mapzone.mapObject.invalidateSize()
          this.$refs.mapzone.mapObject.fitBounds(this.$refs.featurezone.mapObject.getBounds(),{ padding: [50, 50] })
        }
      }, 500)
    }
  }
}
</script>

<style>
  .marker-castpoint {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
</style>
